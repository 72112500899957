import React from 'react';
import { useSelector } from "react-redux";

const DashboardIndex = () => {
    const user = useSelector((state) => state.user);

    return (
        <div id="DashboardIndex" className="container">
            <h1>Hey, schön das du hier bist {user.username}!</h1>
            <p>
                Aktuell laufen hier die Aufbau-Arbeiten für dein persönliches UXNetwork HQ-Dashboard.
            </p>
            <img src="https://i.pinimg.com/originals/eb/1b/27/eb1b27863813653543914d222ceb9cd0.gif" alt="construction" className={"construction"}/>
        </div>
    );
};

export default DashboardIndex;
