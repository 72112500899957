import React from "react";

export default function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer">
            <div className="footer-content">
                <p>
                    © {currentYear} Alexander Koch. All rights reserved.
                </p>
                <p>
                    Designed and developed by <strong>Alexander Koch</strong>.
                </p>
            </div>
        </footer>
    );
}
