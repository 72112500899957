// src/pages/User.js
import React, { useState, useEffect } from 'react';
import { useAuth } from "../../../../../hooks/useAuth";
import axiosInstance from "../../../../../middleware/axiosInstance";
import DataTable from "../../../../components/DataTable";
import DynamicModalForm from "../../../../components/DynamicModalForm";
import DynamicModalDetails from "../../../../components/DynamicModalDetails";

const User = () => {
    const { token } = useAuth();
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const fetchUsers = async () => {
        try {
            const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/admin/user`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            
            const usersWithoutPassword = response.data.map(user => ({
                ...user, // spread the user object
                password: "" // set password to an empty string
            }))

            setUsers(usersWithoutPassword);

        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, [token]);

    const userFields = [
        { name: 'username', label: 'Username', type: 'text', required: true },
        { name: 'email', label: 'Email', type: 'email' },
        { name: 'telephone', label: 'Telephone', type: 'text' },
        { name: 'profilePicture', label: 'Profile Picture URL', type: 'url' },
        { name: 'bio', label: 'Bio', type: 'textarea', rows: 4 },
        { name: 'websiteUrl', label: 'Website URL', type: 'url' },
        { name: 'role', label: 'Role', type: 'select', options: [
                { value: 'user', label: 'User' },
                { value: 'admin', label: 'Admin' },
                { value: 'root', label: 'Root' },
            ]},
        { name: 'password', label: 'Password', type: 'password', required: !isEdit },
        { name: 'status', label: 'Status', type: 'text' },
    ];

    const handleCreate = () => {
        setSelectedUser({});
        setIsEdit(false);
        setShowModal(true);
    };

    const handleEdit = (user) => {
        setSelectedUser(user);
        setIsEdit(true);
        setShowModal(true);
    };

    const handleDetails = (user) => {
        setSelectedUser(user);
        setShowDetails(true);
    };

    const handleDelete = async (user) => {
        const isConfirmed = window.confirm(`Are you sure you want to delete the client ${user.username}?`);

        if (isConfirmed) {
            await axiosInstance.delete(`/admin/user/${user.id}`, user);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isEdit) {
                await axiosInstance.put(`/admin/user/${selectedUser.id}`, selectedUser, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            } else {
                await axiosInstance.post('/admin/user', selectedUser, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            }
            setShowModal(false);
            fetchUsers();
        } catch (error) {
            console.error('Error saving user:', error);
        }
    };

    const handleChange = (e) => {
        setSelectedUser({
            ...selectedUser,
            [e.target.name]: e.target.value,
        });
    };

    const columns = [
        { header: 'Username', accessor: user => user.username },
        { header: 'Email', accessor: user => user.email },
        { header: 'Telephone', accessor: user => user.telephone },
        { header: 'Role', accessor: user => user.role },
        { header: 'Status', accessor: user => user.status },
        { header: 'Actions', accessor: user => (
                <div className={"tableActions"}>
                    <button type="button" className={"btn btn-info"} onClick={() => handleDetails(user)}>Details</button>
                    <button type="button" className={"btn btn-warning"} onClick={() => handleEdit(user)}>Edit</button>
                    <button type="button" className={"btn btn-danger"} onClick={() => handleDelete(user)}>Delete</button>
                </div>
            )
        },
    ];

    return (
        <div>
            <h1>User Management</h1>
            <button onClick={handleCreate} className="btn btn-primary">Create New User</button>
            <DataTable
                data={users}
                columns={columns}
                actionLink="/dashboard/user/details"
                onReload={fetchUsers}
            />
            <DynamicModalForm
                show={showModal}
                handleClose={() => setShowModal(false)}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                entity={selectedUser}
                title={isEdit ? 'Edit User' : 'Create User'}
                fields={userFields}
                isEdit={isEdit}
            />
            <DynamicModalDetails
                show={showDetails}
                handleClose={() => setShowDetails(false)}
                entity={selectedUser}
                title="User Details"
                fields={userFields}
            />
        </div>
    );
};

export default User;
