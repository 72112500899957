import React from 'react';
import { useSelector } from "react-redux";

const Profile = () => {
    const user = useSelector((state) => state.user);

    return (
        <div id="DashboardProfile" className="container">
            <h1 className="profile-heading">Mein Profil</h1>
            <div className="profile-card">
                <div className="profile-picture">
                    {user.profilePicture ? (
                        <img src={user.profilePicture} alt="Profilbild" className="profileImage" />
                    ) : (
                        <div className="placeholder-image">Kein Bild</div>
                    )}
                </div>
                <div className="profile-info">
                    <p><strong>Benutzername:</strong> {user.username}</p>
                    <p><strong>Email:</strong> {user.email}</p>
                    <p><strong>Telefonnummer:</strong> {user.telephone || 'Keine Angabe'}</p>
                    <p>
                        <strong>Website:</strong>{' '}
                        {user.websiteUrl ? (
                            <a href={user.websiteUrl} target="_blank" rel="noopener noreferrer" className="profile-link">
                                {user.websiteUrl}
                            </a>
                        ) : (
                            'Keine Angabe'
                        )}
                    </p>
                    <p><strong>Biografie:</strong> {user.bio || 'Keine Angabe'}</p>
                    <p><strong>Rolle:</strong> {user.role}</p>
                    <p><strong>Erstellt am:</strong> {new Date(user.createdAt).toLocaleString()}</p>
                    <p><strong>Zuletzt aktualisiert:</strong> {new Date(user.updatedAt).toLocaleString()}</p>
                    <p>
                        <strong>Letzte Anmeldung:</strong>{' '}
                        {user.lastLogin ? new Date(user.lastLogin).toLocaleString() : 'Noch keine Anmeldung'}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Profile;
