// src/pages/user/client.js
import React, {useState, useEffect} from 'react';
import { useAuth } from "../../../../../hooks/useAuth";
import axiosInstance from "../../../../../middleware/axiosInstance";
import DataTable from "../../../../components/DataTable";
import DynamicModalForm from '../../../../components/DynamicModalForm';
import DynamicModalDetails from '../../../../components/DynamicModalDetails';

const Client = () => {
    const { token } = useAuth();
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [actionType, setActionType] = useState("")
    const [isFetchingClients, setIsFetchingClients] = useState(false);

    const fetchClients = async () => {
        setIsFetchingClients(true);
        try {
            const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/user/client`);
            setClients(response.data);
            setIsFetchingClients(false);
        } catch (error) {
            console.error('Error fetching clients:', error);
            setIsFetchingClients(false);
        }
    };

    useEffect(() => {
        fetchClients();
    }, [token]);

    const clientFields = [
        { name: 'clientId', label: 'Client ID', type: 'text', required: true },
        { name: 'name', label: 'Name', type: 'text', required: true },
        { name: 'email', label: 'Email', type: 'email' },
        { name: 'telephone', label: 'Telephone', type: 'text' },
        { name: 'address', label: 'Address', type: 'textarea', rows: 2 },
        { name: 'companyName', label: 'Company Name', type: 'text' },
        { name: 'websiteUrl', label: 'Website URL', type: 'url' },
        { name: 'notes', label: 'Notes', type: 'textarea', rows: 4 },
        { name: 'status', label: 'Status', type: 'select', options: [
                { value: 'active', label: 'Active' },
                { value: 'inactive', label: 'Inactive' },
            ]},
    ];


    const handleCreate = () => {
        setSelectedClient({});
        setActionType("create");
        setShowModal(true);
    };

    const handleEdit = (client) => {
        setSelectedClient(client);
        setActionType("edit");
        setShowModal(true);
    };

    const handleDelete = async (client) => {
        const isConfirmed = window.confirm(`Are you sure you want to delete the client ${client.name}?`);

        if (isConfirmed) {
            await axiosInstance.delete(`/user/client/${client.id}`, client);
        }
    };

    const handleDetails = (client) => {
        setSelectedClient(client);
        setShowDetails(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(actionType)
        try {
            if (actionType === "edit") {
                await axiosInstance.put(`/user/client/${selectedClient.id}`, selectedClient, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            } else if (actionType === "create") {
                console.log(selectedClient)
                await axiosInstance.post('/user/client', selectedClient, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            }
            setShowModal(false);
            fetchClients();
        } catch (error) {
            console.error('Error saving client:', error);
        }
    };

    const handleChange = (e) => {
        setSelectedClient({
            ...selectedClient,
            [e.target.name]: e.target.value,
        });
    };

    const columns = [
        { header: 'Name', accessor: client => client.name },
        { header: 'Mail', accessor: client => client.email },
        { header: 'Website', accessor: client => client.websiteUrl },
        { header: 'Actions', accessor: client => (
                <div className={"tableActions"}>
                    <button type="button" className={"btn btn-info"} onClick={() => handleDetails(client)}>Details</button>
                    <button type="button" className={"btn btn-warning"} onClick={() => handleEdit(client)}>Edit</button>
                    <button type="button" className={"btn btn-danger"} onClick={() => handleDelete(client)}>Delete</button>
                </div>
            )
        },
    ];

    return (
        <div>
            <h1>Client Management</h1>
            <button onClick={handleCreate} className="btn btn-primary">Create New Client</button>
            <DataTable
                data={clients}
                columns={columns}
                actionLink="/dashboard/user/client/details"
                onReload={fetchClients}
                isLoading={isFetchingClients}
            />
            <DynamicModalForm
                show={showModal}
                handleClose={() => setShowModal(false)}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                entity={selectedClient}
                title={(actionType === "edit") ? 'Edit Client' : 'Create Client'}
                fields={clientFields}
                isEdit={(actionType === "edit")}
            />
            <DynamicModalDetails
                show={showDetails}
                handleClose={() => setShowDetails(false)}
                entity={selectedClient}
                title="Client Details"
                fields={clientFields}
            />
        </div>
    );
};

export default Client;
