import React from "react";
import { generateUUID } from "../../utils/generateUUID";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import logo from "../../assets/images/themes/light/logo.png";

export default function Header() {
    const settings = useSelector((state) => state.settings);
    const user = useSelector((state) => state.user);

    const globalLinks = [];

    const loggedOutLinks = [];

    const loggedInLinks = [
        {
            link: "/dashboard",
            title: "Dashboard",
        },
        {
            link: "/logout",
            title: "Logout",
        },
    ];

    return (
        <header className="header">
            <div className="header-left">
                <Link to="/" className="logo-container">
                    <img src={logo} alt="UX-NETWORK Logo" className="logo" />
                    <h1 className="site-title">HQ</h1>
                </Link>
            </div>
            <div className="header-right">
                {globalLinks.map((link) => (
                    <Link to={link.link} key={generateUUID()} title={link.title} className="nav-link">
                        <button className="btn btn-link">{link.title}</button>
                    </Link>
                ))}
                {settings.isLoggedIn && user
                    ? loggedInLinks.map((link) => (
                        <Link to={link.link} key={generateUUID()} title={link.title} className="nav-link">
                            <button className="btn btn-outline-primary">{link.title}</button>
                        </Link>
                    ))
                    : loggedOutLinks.map((link) => (
                        <Link to={link.link} key={generateUUID()} title={link.title} className="nav-link">
                            <button className="btn btn-outline-primary">{link.title}</button>
                        </Link>
                    ))}
            </div>
        </header>
    );
}
