import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';


const Dashboard = () => {
    const user = useSelector((state) => state.user);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <div id="Dashboard" className="d-flex">
            {/* Sidebar */}
            <div className={`sidebar ${sidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
                <button className="toggle-btn" onClick={toggleSidebar}>
                    <i className={`bi ${sidebarOpen ? 'bi-chevron-left' : 'bi-list'}`}></i>
                </button>
                <div className="content">
                    <div className={"d-flex flex-row align-items-center justify-content-start gap-2 "}>
                        <i className="bi bi-person-circle"></i>
                        {sidebarOpen && <h2 className={"m-0"}>Dashboard</h2>}
                    </div>
                    <nav>
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <Link className="nav-link" to="/dashboard/profile">
                                    <i className="bi bi-person"></i>
                                    {sidebarOpen && <span>Mein Profil</span>}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/dashboard/mail">
                                    <i className="bi bi-envelope"></i>
                                    {sidebarOpen && <span>Mails</span>}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/dashboard/client">
                                    <i className="bi bi-briefcase"></i>
                                    {sidebarOpen && <span>Clients</span>}
                                </Link>
                            </li>
                        </ul>
                        {(user?.role === 'admin' || user?.role === 'root') && (
                            <div className="admin-section">
                                <div className={"d-flex flex-row align-items-center justify-content-start gap-2"}>
                                    <i className="bi bi-buildings"></i>
                                    {sidebarOpen && <h2 className={"m-0"}>Admin</h2>}
                                </div>
                                <ul className="nav flex-column">
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/dashboard/admin/user">
                                            <i className="bi bi-people"></i>
                                            {sidebarOpen && <span>User Management</span>}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </nav>
                </div>
            </div>

            {/* Main Content */}
            <div className={`container-fluid ${sidebarOpen ? '' : 'content-shifted'}`}>
                <Outlet />
            </div>
        </div>
    );
};

export default Dashboard;
