import React, { useState, useEffect } from 'react';
import { useAuth } from "../../../../../hooks/useAuth";
import axiosInstance from "../../../../../middleware/axiosInstance";
import DataTable from "../../../../components/DataTable";
import DynamicModalForm from "../../../../components/DynamicModalForm";
import DynamicModalDetails from "../../../../components/DynamicModalDetails";

const Mail = () => {
    const { token } = useAuth();
    const [mails, setMails] = useState([]);
    const [selectedMail, setSelectedMail] = useState({});
    const [showFormModal, setShowFormModal] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const mailFields = [
        { name: 'subject', label: 'Subject', type: 'text', required: true },
        { name: 'fromEmail', label: 'From Email', type: 'email', required: true },
        { name: 'toEmail', label: 'To Email', type: 'emailWithClients', required: true },
        { name: 'cc', label: 'CC', type: 'email' },
        { name: 'bcc', label: 'BCC', type: 'email' },
        { name: 'sendDate', label: 'Send Date', type: 'date', required: true },
        { name: 'body', label: 'Body', type: 'htmlWithDisplayMail', rows: 10, required: true },
        { name: 'frequency', label: 'Frequency', type: 'select', options: [
                { value: 'none', label: 'None' },
                { value: 'daily', label: 'Daily' },
                { value: 'weekly', label: 'Weekly' },
                { value: 'monthly', label: 'Monthly' },
            ]},
        { name: 'status', label: 'Status', type: 'select', options: [
                { value: 'draft', label: 'Draft' },
                { value: 'sent', label: 'Sent' },
                { value: 'scheduled', label: 'Scheduled' },
            ]},
        { name: 'attachments', label: 'Attachments', type: 'attachments' }
    ];

    const fetchMails = async () => {
        setIsLoading(true);
        setError('');
        try {
            const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/user/mail`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setMails(response.data);
        } catch (error) {
            setError('Error fetching mails. Please try again later.');
            console.error('Error fetching mails:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchMails();
    }, [token]);

    const handleCreate = () => {
        setSelectedMail({});
        setIsEdit(false);
        setShowFormModal(true);
    };

    const handleEdit = (mail) => {
        setSelectedMail(mail);
        setIsEdit(true);
        setShowFormModal(true);
    };

    const handleDetails = (mail) => {
        setSelectedMail(mail);
        setShowDetailsModal(true);
    };

    const handleDelete = async (mail) => {
        const isConfirmed = window.confirm(`Are you sure you want to delete the mail "${mail.subject}"?`);
        if (isConfirmed) {
            setError('');
            setSuccess('');
            setIsLoading(true);
            try {
                await axiosInstance.delete(`/user/mail/${mail.id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setSuccess('Mail successfully deleted.');
                fetchMails();
            } catch (error) {
                setError('Error deleting mail. Please try again later.');
                console.error('Error deleting mail:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleSend = async (mail) => {
        const isConfirmed = window.confirm(`Are you sure you want to send the mail "${mail.subject}"?`);
        if (isConfirmed) {
            setError('');
            setSuccess('');
            setIsLoading(true);
            try {
                await axiosInstance.post(`/user/mail/${mail.id}/send`, {}, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setSuccess('Mail sent successfully.');
                fetchMails();
            } catch (error) {
                setError('Error sending mail. Please try again later.');
                console.error('Error sending mail:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        setIsLoading(true);

        try {
            if (isEdit) {
                await axiosInstance.put(`/user/mail/${selectedMail.id}`, selectedMail, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setSuccess('Mail updated successfully.');
            } else {
                await axiosInstance.post('/user/mail', selectedMail, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setSuccess('Mail created successfully.');
            }

            setShowFormModal(false);
            fetchMails();
        } catch (error) {
            setError('Error saving mail. Please try again later.');
            console.error('Error saving mail:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSelectedMail({
            ...selectedMail,
            [name]: value,
        });
    };

    const columns = [
        { header: 'Subject', accessor: mail => mail.subject },
        { header: 'From Email', accessor: mail => mail.fromEmail },
        { header: 'To Email', accessor: mail => mail.toEmail || 'N/A' },
        { header: 'Status', accessor: mail => mail.status },
        { header: 'Actions', accessor: mail => (
                <div className="tableActions">
                    <button type="button" className="btn btn-primary" onClick={() => handleSend(mail)}>Send</button>
                    <button type="button" className="btn btn-info" onClick={() => handleDetails(mail)}>Details</button>
                    <button type="button" className="btn btn-warning" onClick={() => handleEdit(mail)}>Edit</button>
                    <button type="button" className="btn btn-danger" onClick={() => handleDelete(mail)}>Delete</button>
                </div>
            )
        },
    ];

    return (
        <div>
            <h1>Mail Management</h1>
            {error && <div className="alert alert-danger">{error}</div>}
            {success && <div className="alert alert-success">{success}</div>}
            {isLoading && <div>Loading...</div>}
            <button onClick={handleCreate} className="btn btn-primary">Create New Mail</button>
            <DataTable data={mails} columns={columns} />
            <DynamicModalForm
                show={showFormModal}
                handleClose={() => setShowFormModal(false)}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                entity={selectedMail}
                title={isEdit ? 'Edit Mail' : 'Create Mail'}
                fields={mailFields}
                isEdit={isEdit}
            />
            <DynamicModalDetails
                show={showDetailsModal}
                handleClose={() => setShowDetailsModal(false)}
                entity={selectedMail}
                title="Mail Details"
                fields={mailFields}
            />
        </div>
    );
};

export default Mail;
