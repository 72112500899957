import React, {useEffect, useRef, useState} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import axiosInstance from "../../middleware/axiosInstance";
import AttachmentModal from "./AttachmentModal";

const availableVariables = [
    {variable: '{{client_name}}', description: 'The name of the client receiving the email (e.g., "John Doe")'},
    {variable: '{{client_websiteUrl}}', description: 'The client\'s website URL (e.g., "https://clientwebsite.com")'},
    {variable: '{{client_companyName}}', description: 'The name of the client\'s company (e.g., "Acme Corporation")'},
    {variable: '{{client_email}}', description: 'The client\'s email address (e.g., "john.doe@example.com")'},
    {
        variable: '{{client_address}}',
        description: 'The client\'s physical address (e.g., "123 Main St, Springfield, IL")'
    },
    {variable: '{{client_phone}}', description: 'The client\'s phone number (e.g., "+1 (555) 123-4567")'},

    {variable: '{{user_name}}', description: 'The name of the user sending the email (e.g., "Jane Smith")'},
    {variable: '{{user_email}}', description: 'The user\'s email address (e.g., "jane.smith@company.com")'},
    {variable: '{{user_telephone}}', description: 'The user\'s phone number (e.g., "+1 (555) 987-6543")'},
    {
        variable: '{{user_websiteUrl}}',
        description: 'The website URL of the user\'s company (e.g., "https://yourcompany.com")'
    },
    {variable: '{{user_company}}', description: 'The name of the sender\'s company (e.g., "Your Company LLC")'},

    {variable: '{{Date}}', description: 'The current date formatted as DD.MM.YYYY (e.g., "04.10.2024")'},
    {variable: '{{Date.Day}}', description: 'The day of the month (e.g., "04")'},
    {variable: '{{Date.MonthName}}', description: 'The full name of the current month (e.g., "October")'},
    {variable: '{{Date.Year}}', description: 'The current year (e.g., "2024")'},
    {variable: '{{Date.Hour}}', description: 'The current hour in 24-hour format (e.g., "14" for 2 PM)'},
    {variable: '{{Date.Minute}}', description: 'The current minute (e.g., "30")'},
    {
        variable: '{{DateTime}}',
        description: 'The current date and time formatted as DD.MM.YYYY HH:mm (e.g., "04.10.2024 14:30")'
    },
    {variable: '{{Date.TimeZone}}', description: 'The current timezone offset (e.g., "+02:00")'},

    {variable: '{{app_name}}', description: 'The name of your application or company (e.g., "My App")'},
    {variable: '{{app_url}}', description: 'The main URL of your application (e.g., "https://myapp.com")'},
    {
        variable: '{{app_supportEmail}}',
        description: 'The support email address for your app (e.g., "support@myapp.com")'
    }
];


const DynamicModalForm = ({
                              show,
                              handleClose,
                              handleSubmit,
                              handleChange,
                              entity = {},
                              title,
                              fields,
                              isEdit
                          }) => {
        const [clients, setClients] = useState([]); // State to store clients
        const [liveHtml, setLiveHtml] = useState(''); // State to manage live view HTML content
        const [isLiveViewVisible, setIsLiveViewVisible] = useState(false); // State to toggle iframe visibility
        const [isEmailDropdown, setIsEmailDropdown] = useState(false); // State to toggle between email dropdown and input
        const [emailInputs, setEmailInputs] = useState({}); // State to manage input for each email field
        const [emailLists, setEmailLists] = useState({}); // State to manage multiple emails per field
        const iframeRef = useRef(null); // Ref for iframe
        const [showVariables, setShowVariables] = useState(false); // State to show/hide variables list
        const [showAttachmentModal, setShowAttachmentModal] = useState(false);
        const [currentAttachmentField, setCurrentAttachmentField] = useState(null);
        const [editingAttachment, setEditingAttachment] = useState(null);
        const [editingAttachmentIndex, setEditingAttachmentIndex] = useState(null);

        // Fetch clients from API on component mount
        useEffect(() => {
            const fetchClients = async () => {
                try {
                    const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/user/client`);
                    setClients(response.data);
                } catch (error) {
                    console.error("Error fetching clients: ", error);
                }
            };

            fetchClients();
        }, []);

        useEffect(() => {
            // Initialize emailLists from entity when editing an existing email or loading the form
            if (entity.toEmail) {
                setEmailLists({toEmail: Array.isArray(entity.toEmail) ? entity.toEmail : []});
            }
        }, [entity]); // This will trigger when the `entity` changes (e.g., when editing an existing mail)


        // Initialize emailInputs and emailLists when entity changes (for editing existing emails)
        useEffect(() => {
            if (isEdit && entity) {
                // Populate emailLists from entity data
                setEmailLists({
                    toEmail: Array.isArray(entity.toEmail) ? entity.toEmail : [],
                    cc: Array.isArray(entity.cc) ? entity.cc : [],
                    bcc: Array.isArray(entity.bcc) ? entity.bcc : [],
                });

                // Initialize emailInputs with empty strings for each email field
                setEmailInputs({
                    toEmail: '',
                    cc: '',
                    bcc: ''
                });
            }
        }, [entity, isEdit]);

        const handleLiveViewToggle = () => {
            const htmlField = fields.find(field => field.type === 'htmlWithDisplay' || field.type === 'htmlWithDisplayMail');
            if (htmlField) {
                setLiveHtml(entity[htmlField.name] || ''); // Update live view with the latest HTML from the textarea
            }
            setIsLiveViewVisible(!isLiveViewVisible); // Toggle iframe visibility
        };

        const handleEmailInputChange = (fieldName, value) => {
            setEmailInputs(prevState => ({
                ...prevState,
                [fieldName]: value // Update the input value for the specific field
            }));
        };

        const handleAddEmail = (fieldName) => {
            const emailInput = emailInputs[fieldName] || '';
            const currentEmails = emailLists[fieldName] || [];

            // Ensure the email input is not empty and is not already in the list
            if (emailInput && !currentEmails.includes(emailInput)) {
                setEmailLists(prevState => ({
                    ...prevState,
                    [fieldName]: [...currentEmails, emailInput] // Add the new email to the list
                }));

                // Update entity to persist the email list in the form state
                handleChange({
                    target: {
                        name: fieldName,
                        value: [...currentEmails, emailInput], // Update the entity's email list
                    }
                });

                // Clear the input after adding
                setEmailInputs(prevState => ({
                    ...prevState,
                    [fieldName]: '' // Clear the input field
                }));
            }
        };

        const handleRemoveEmail = (fieldName, index) => {
            const currentEmails = emailLists[fieldName] || [];
            const updatedEmails = currentEmails.filter((_, i) => i !== index);

            setEmailLists(prevState => ({
                ...prevState,
                [fieldName]: updatedEmails // Update the list after removing the email
            }));

            // Update entity to reflect the updated email list
            handleChange({
                target: {
                    name: fieldName,
                    value: updatedEmails, // Update the entity's email list
                }
            });
        };


        // Function to format a date in local time zone for datetime-local input
        const formatLocalDateTime = (dateString) => {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            return `${year}-${month}-${day}T${hours}:${minutes}`;
        };

        const handleAddAttachment = (attachment) => {
            if (currentAttachmentField) {
                const currentAttachments = entity[currentAttachmentField] || []; // Ensure it's an array
                const updatedAttachments = [...currentAttachments, attachment]; // Add new attachment

                handleChange({
                    target: {name: currentAttachmentField, value: updatedAttachments} // Update the attachments array
                });
            }
        };


        const handleRemoveAttachment = (fieldName, index) => {
            const currentAttachments = entity[fieldName] || []; // Ensure it's an array
            const updatedAttachments = currentAttachments.filter((_, i) => i !== index); // Remove the selected attachment

            handleChange({
                target: {name: fieldName, value: updatedAttachments} // Update the attachments array
            });
        };


        const handleToggleVariables = () => {
            setShowVariables(!showVariables); // Toggle visibility of variables list
        };

        const handleEditAttachment = (fieldName, index, updatedAttachment) => {
            const currentAttachments = entity[fieldName] || [];
            const updatedAttachments = currentAttachments.map((attachment, i) =>
                i === index ? updatedAttachment : attachment // Ersetzen des Anhangs an der angegebenen Position
            );

            handleChange({
                target: {name: fieldName, value: updatedAttachments}, // Update der Anhangsliste
            });
        };


        const renderField = (field) => {
                switch (field.type) {
                    case 'text':
                    case 'password':
                    case 'url':
                        return (
                            <Form.Group key={field.name}>
                                <Form.Label>{field.label}</Form.Label>
                                <Form.Control
                                    type={field.type}
                                    name={field.name}
                                    value={entity[field.name] || ''}
                                    onChange={handleChange}
                                    required={field.required}
                                />
                            </Form.Group>
                        );
                    case 'email':
                        // Handle single email input (like fromEmail)
                        return (
                            <Form.Group key={field.name}>
                                <Form.Label>{field.label}</Form.Label>
                                <Form.Control
                                    type="email"
                                    name={field.name}
                                    value={entity[field.name] || ''} // For single email, use the entity value
                                    onChange={handleChange}
                                    required={field.required}
                                />
                            </Form.Group>
                        );
                    case 'emailWithClients':
                        return (
                            <Form.Group key={field.name}>
                                <Form.Label>{field.label}</Form.Label>
                                {isEmailDropdown ? (
                                    <Form.Control
                                        as="select"
                                        value={emailInputs[field.name] || ''}
                                        onChange={(e) => handleEmailInputChange(field.name, e.target.value)}
                                    >
                                        <option value="">Select Email</option>
                                        {clients.map((client, index) => (
                                            <option key={index} value={client.email}>
                                                {client.email}
                                            </option>
                                        ))}
                                    </Form.Control>
                                ) : (
                                    <Form.Control
                                        type="email"
                                        value={emailInputs[field.name] || ''}
                                        onChange={(e) => handleEmailInputChange(field.name, e.target.value)}
                                    />
                                )}
                                <Button
                                    type="button"
                                    className="btn btn-outline-primary mt-2"
                                    onClick={() => setIsEmailDropdown(!isEmailDropdown)} // Toggle between dropdown and free text
                                >
                                    {isEmailDropdown ? 'Enter Email Manually' : 'Choose from Clients'}
                                </Button>

                                <Button className="btn btn-primary mt-2" onClick={() => handleAddEmail(field.name)}>
                                    Add Email
                                </Button>

                                <ul className="mt-2">
                                    {(emailLists[field.name] || []).map((email, index) => (
                                        <li key={index}>
                                            {email}
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                className="ml-2"
                                                onClick={() => handleRemoveEmail(field.name, index)}
                                            >
                                                Remove
                                            </Button>
                                        </li>
                                    ))}
                                </ul>
                            </Form.Group>
                        );
                    case 'date':
                        return (
                            <Form.Group key={field.name}>
                                <Form.Label>{field.label}</Form.Label>
                                <Form.Control
                                    type="datetime-local" // Use datetime-local for both date and time
                                    name={field.name}
                                    value={(entity[field.name] && !isNaN(new Date(entity[field.name]).getTime()))
                                        ? formatLocalDateTime(entity[field.name]) // Use a custom function to format date and time in local timezone
                                        : ''} // Fallback to empty string if the date is invalid
                                    onChange={handleChange}
                                    required={field.required}
                                />
                            </Form.Group>
                        );
                    case 'select':
                        return (
                            <Form.Group key={field.name}>
                                <Form.Label>{field.label}</Form.Label>
                                <Form.Control
                                    as="select"
                                    name={field.name}
                                    value={entity[field.name] || ''}
                                    onChange={handleChange}
                                    required={field.required}
                                >
                                    {field.options.map(option => (
                                        <option key={option.value} value={option.value}>{option.label}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        );
                    case 'textarea':
                        return (
                            <Form.Group key={field.name}>
                                <Form.Label>{field.label}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name={field.name}
                                    value={entity[field.name] || ''}
                                    onChange={handleChange}
                                    rows={field.rows || 3}
                                    required={field.required}
                                />
                            </Form.Group>
                        );
                    case 'htmlWithDisplay': // Handle HTML input field
                        return (
                            <Form.Group key={field.name}>
                                <Form.Label>{field.label}</Form.Label>
                                {/* HTML Input */}
                                <Form.Control
                                    as="textarea"
                                    name={field.name}
                                    value={entity[field.name] || ''}
                                    onChange={handleChange} // Update entity with the typed content
                                    rows={field.rows || 5}
                                    required={field.required}
                                />

                                {/* Button to toggle live view */}
                                <Button
                                    type="button"
                                    className="btn btn-primary mt-2"
                                    onClick={handleLiveViewToggle} // Call the function to toggle iframe
                                >
                                    {isLiveViewVisible ? 'HIDE VIEW' : 'LIVE VIEW'}
                                </Button>

                                {/* Conditionally render the HTML output inside an iframe */}
                                {isLiveViewVisible && (
                                    <div style={{marginTop: '15px'}}>
                                        <h5>HTML Output:</h5>
                                        <iframe
                                            ref={iframeRef}
                                            title="HTML Preview"
                                            style={{width: '100%', height: '400px', border: '1px solid #ddd'}}
                                            srcDoc={liveHtml} // Use the state to update iframe content
                                        />
                                    </div>
                                )}
                            </Form.Group>
                        );
                    case 'htmlWithDisplayMail': // Handle HTML input field
                        return (
                            <Form.Group key={field.name}>
                                <Form.Label>{field.label}</Form.Label>
                                {/* HTML Input */}
                                <Form.Control
                                    as="textarea"
                                    name={field.name}
                                    value={entity[field.name] || ''}
                                    onChange={handleChange} // Update entity with the typed content
                                    rows={field.rows || 5}
                                    required={field.required}
                                />

                                {/* Button to toggle live view */}
                                <Button
                                    type="button"
                                    className="btn btn-primary mt-2"
                                    onClick={handleLiveViewToggle} // Call the function to toggle iframe
                                >
                                    {isLiveViewVisible ? 'HIDE VIEW' : 'LIVE VIEW'}
                                </Button>

                                {/* Button to toggle variables list */}
                                <Button
                                    type="button"
                                    className="btn btn-info mt-2 ml-2"
                                    onClick={handleToggleVariables} // Call the function to show available variables
                                >
                                    {showVariables ? 'Hide Variables' : 'Show Variables'}
                                </Button>

                                {/* Conditionally render the HTML output inside an iframe */}
                                {isLiveViewVisible && (
                                    <div style={{marginTop: '15px'}}>
                                        <h5>HTML Output:</h5>
                                        <iframe
                                            ref={iframeRef}
                                            title="HTML Preview"
                                            style={{width: '100%', height: '400px', border: '1px solid #ddd'}}
                                            srcDoc={liveHtml} // Use the state to update iframe content
                                        />
                                    </div>
                                )}
                                {showVariables && (
                                    <div className="mt-3">
                                        <h5>Available Variables:</h5>
                                        <ul>
                                            {availableVariables.map((item, index) => (
                                                <li key={index}>
                                                    <strong>{item.variable}</strong> - {item.description}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}

                            </Form.Group>
                        );
                    case 'attachments':
                        const attachments = entity[field.name] || [];
                        return (
                            <Form.Group key={field.name}>
                                <Form.Label>{field.label}</Form.Label>
                                <Button
                                    variant="outline-primary"
                                    onClick={() => {
                                        setCurrentAttachmentField(field.name);
                                        setShowAttachmentModal(true);
                                    }}
                                >
                                    Add Attachment
                                </Button>
                                <ul className="mt-3">
                                    {attachments.map((attachment, index) => (
                                        <li key={index}>
                                            {attachment.filename} ({attachment.contentType})
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                className="ml-2"
                                                onClick={() => handleRemoveAttachment(field.name, index)}
                                            >
                                                Remove
                                            </Button>
                                            <Button
                                                variant="secondary"
                                                size="sm"
                                                className="ml-2"
                                                onClick={() => {
                                                    setCurrentAttachmentField(field.name);
                                                    setShowAttachmentModal(true);
                                                    setEditingAttachmentIndex(index); // Tracke den Index des zu bearbeitenden Anhangs
                                                    setEditingAttachment(attachment); // Lade die Daten des Anhangs
                                                }}
                                            >
                                                Edit
                                            </Button>
                                        </li>
                                    ))}
                                </ul>
                            </Form.Group>
                        );
                    default:
                        return null;
                }
            }
        ;

        return (
            <>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={handleSubmit}>
                        <Modal.Body>
                            {fields.map(renderField)}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button type="submit" variant="primary">
                                {isEdit ? 'Update' : 'Create'}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
                {/* Render the AttachmentModal */}
                <AttachmentModal
                    showModal={showAttachmentModal}
                    handleClose={() => {
                        setShowAttachmentModal(false);
                        setEditingAttachment(null); // Reset editing state
                    }}
                    addAttachment={handleAddAttachment}
                    editAttachment={(updatedAttachment) => handleEditAttachment(currentAttachmentField, editingAttachmentIndex, updatedAttachment)}
                    editingAttachment={editingAttachment}
                />
            </>
        );
    }
;

export default DynamicModalForm;
