// src/pages/Home.js
import React, {useState} from 'react';
import {useAuth} from "../../../hooks/useAuth";

const Home = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth();
    const handleSubmit = async (e) => {
        e.preventDefault();
        await login(username, password);
    };

    return (
        <div id={"Home"}>
            <form onSubmit={handleSubmit} className={"floatingContainer"}>
                <label>
                    <p className={"opacity-50"}>
                        Benutzername:
                    </p>
                    <input type="text" value={username} onChange={(e) => setUsername(e.target.value)}
                           placeholder="Username"/>
                </label>
                <label>
                    <p className={"opacity-50"}>
                        Password:
                    </p>
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)}
                           placeholder="Password"/>
                </label>
                <button type="submit" className={"btn btn-primary"}>Login</button>
            </form>
        </div>
    );
};

export default Home;
