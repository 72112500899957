import React, {useEffect, useRef, useState} from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const DynamicModalDetails = ({ show, handleClose, entity = {}, title, fields }) => {

    const [liveHtml, setLiveHtml] = useState(''); // State to manage live view HTML content
    const iframeRef = useRef(null); // Ref for iframe

    // Recursive function to render nested objects or arrays
    const renderNestedField = (field, value) => {
        if (Array.isArray(value)) {
            return (
                <ul>
                    {value.map((item, index) => (
                        <li key={index}>{typeof item === 'object' ? renderNestedField(null, item) : item}</li>
                    ))}
                </ul>
            );
        } else if (typeof value === 'object' && value !== null) {
            return (
                <div className="nested-fields">
                    {Object.entries(value).map(([key, nestedValue]) => (
                        <Form.Group key={key}>
                            <Form.Label>{key.charAt(0).toUpperCase() + key.slice(1)}:</Form.Label>
                            <div>
                                {renderNestedField(null, nestedValue)}
                            </div>
                        </Form.Group>
                    ))}
                </div>
            );
        } else {
            return value || 'N/A';
        }
    };

    const handleLiveViewToggle = () => {
        const htmlField = fields.find(field => field.type === 'htmlWithDisplay' || field.type === 'htmlWithDisplayMail');
        if (htmlField) {
            setLiveHtml(entity[htmlField.name] || ''); // Update live view with the latest HTML from the textarea
        }
    };


    // Render a single field
    const renderField = (field) => {
        const value = field.name.split('.').reduce((obj, key) => obj?.[key], entity);

        switch (field.type) {
            case 'attachments':
                const attachments = value || [];
                return (
                    <Form.Group key={field.name}>
                        <Form.Label>{field.label}:</Form.Label>
                        <div className="form-control-plaintext">
                            {attachments.length > 0 ? (
                                <ul>
                                    {attachments.map((attachment, index) => (
                                        <li key={index}>
                                            <strong>{attachment.filename}</strong>
                                            <span> ({attachment.contentType})</span> -{' '}
                                            <a
                                                href={attachment.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                View/Download
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <div>No attachments available</div>
                            )}
                        </div>
                    </Form.Group>
                );
            case 'email':
            case 'emailWithClients':
                const emails = Array.isArray(value) ? value : [value];
                return (
                    <Form.Group key={field.name}>
                        <Form.Label>{field.label}:</Form.Label>
                        <div>
                            {emails.length > 0 && emails[0] !== '' ? emails.join(', ') : 'N/A'}
                        </div>
                    </Form.Group>
                );
            case 'htmlWithDisplay': // Handle HTML input field
                return (
                    <Form.Group key={field.name}>
                        <Form.Label>{field.label}</Form.Label>
                        {/* Conditionally render the HTML output inside an iframe */}
                            <div style={{marginTop: '15px'}}>
                                <h5>HTML Output:</h5>
                                <iframe
                                    ref={iframeRef}
                                    title="HTML Preview"
                                    style={{width: '100%', height: '400px', border: '1px solid #ddd'}}
                                    srcDoc={liveHtml} // Use the state to update iframe content
                                />
                            </div>
                        {/* Button to toggle live view */}
                        <Button
                            type="button"
                            className="btn btn-primary mt-2"
                            onClick={handleLiveViewToggle} // Call the function to toggle iframe
                        >
                            LIVE VIEW
                        </Button>
                    </Form.Group>
                );
            case 'htmlWithDisplayMail': // Handle HTML input field
                return (
                    <Form.Group key={field.name}>
                        <Form.Label>{field.label}</Form.Label>
                        {/* Conditionally render the HTML output inside an iframe */}
                        <div style={{marginTop: '15px'}}>
                            <h5>HTML Output:</h5>
                            <iframe
                                ref={iframeRef}
                                title="HTML Preview"
                                style={{width: '100%', height: '400px', border: '1px solid #ddd'}}
                                srcDoc={liveHtml} // Use the state to update iframe content
                            />
                        </div>
                        {/* Button to toggle live view */}
                        <Button
                            type="button"
                            className="btn btn-primary mt-2"
                            onClick={handleLiveViewToggle} // Call the function to toggle iframe
                        >
                            LIVE VIEW
                        </Button>
                    </Form.Group>
                );
                default:
                return (
                    <Form.Group key={field.name}>
                        <Form.Label>{field.label}:</Form.Label>
                        <div>
                            {renderNestedField(field, value)}
                        </div>
                    </Form.Group>
                );
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {fields.map(renderField)}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DynamicModalDetails;
