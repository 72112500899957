
import React, {useEffect} from 'react'
import { useAuth } from '../../../hooks/useAuth';
import {useNavigate} from "react-router-dom";

function Logout() {

    const navigate = useNavigate();

    const { logout } = useAuth();

    useEffect(() => {
        logout().then(()=>{
            setTimeout(()=>{
                navigate("/");
            }, 2000)
        })
    }, []);


    return (
        <div>
            <h2>Deine Session wird aufegelöst, nachdem du die Dashboard Seite neulädst!</h2>
        </div>
    )
}

export default Logout
