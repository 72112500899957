// src/App.js
import React, {useEffect} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import Home from './views/pages/Home/Home';
import Dashboard from './views/pages/Dashboard/Dashboard';

import DashboardMail from './views/pages/Dashboard/User/Mail/Mail';

import DashboardClient from './views/pages/Dashboard/User/Client/Clients';
import DashboardUser from './views/pages/Dashboard/Admin/User/User';
import DashboardProfile from './views/pages/Dashboard/User/Profile/Profile';

import Logout from "./views/pages/Logout/Logout";
import SiteNotFound from "./views/pages/SiteNotFound/SiteNotFound";

import Header from "./views/layouts/Header";
import Footer from "./views/layouts/Footer";
import {ToastContainer} from "react-bootstrap";
import {useSelector} from "react-redux";
import {useAuth} from "./hooks/useAuth";
import DashboardIndex from "./views/pages/Dashboard/Index/Index";

function App() {

    const user = useSelector((state) => state.user);

    useAuth();

    return (
        <>
            <ToastContainer />
            <Header/>
            <div id={"rootApp"}>
                <Routes>
                    <Route path="/" element={!user ? <Home/> : <Navigate to="/dashboard" />} component={Home}/>
                    <Route path="/logout" element={<Logout/>}/>
                    {user ? (<>
                    <Route path="/dashboard" element={user ? <Dashboard/> : <Home/>}>
                        { /* ROLE USER DASHBOARD */}
                        <Route path="" element={<DashboardIndex />}/>
                        <Route path="profile" element={<DashboardProfile/>}/>
                        <Route path="mail" element={<DashboardMail/>}/>
                        <Route path="client" element={<DashboardClient/>}/>
                        { /* ROLE ADMIN DASHBOARD */}
                        {(user?.role === 'admin' || user?.role === 'root') && (
                            <Route path="admin/user" element={<DashboardUser/>}/>
                        )}
                    </Route>
                        </>
                    ) : null}
                    <Route path="*" element={<SiteNotFound/>}/>
                </Routes>
            </div>
            <Footer/>
        </>
    );
}

export default App;
