// src/store/userSlice.js
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axiosInstance from "../middleware/axiosInstance";

export const updateUserAPI = createAsyncThunk('user/updateUserAPI', async (newUser, { rejectWithValue }) => {
    try {
        updateUser(newUser);
        const response = await axiosInstance.put('/user/me', { user: newUser });
        return response.data.user;  // Assuming the updated user are returned
    } catch (error) {
        return rejectWithValue(error.response.data || 'Failed to update user');
    }
});

const initialState = null;

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetUser() {
            // Reset user to default values
            return null;
        },
        setUser(state, action) {
            // Set User to default values
            return action.payload;
        },
        updateUser(state, action) {
            // Set Settings to default values
            return { ...action.payload };
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle updateUserAPI (both API and localStorage)
            .addCase(updateUserAPI.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateUserAPI.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(updateUserAPI.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const { resetUser, setUser, updateUser} = userSlice.actions;

export default userSlice.reducer;
